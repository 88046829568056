import { required, minLength, maxLength } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            url: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            seoTitle: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(110),
            },
            seoDescription: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
            seoKeywords: {
            },
        },
    }
}

export default { getValidations };