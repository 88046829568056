<template>
  <div class="animated fadeIn">
    <PhotoEditorModal
      :modalTitle="$t('edit')"
      :show="editModal"
      @hide="editModal = false"
      :fileModel="form.featuredImage"
      :validHeight="editHeight"
      :validWidth="editWidth"
      :cropIds="[]"
      :activeMenu="[
        'flip',
        'crop',
        'resize',
        'draw',
        'shape',
        'icon',
        'text',
        'mask',
        'filter',
      ]"
      @onSave="(base64) => saveAndUpload(base64)"
    >
    </PhotoEditorModal>
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="8" md="8" xs="12" offset-sm="2">
          <b-card>
            <div slot="header">
              <strong>
                <i class="fa fa-copy" />
                {{ page.id == 0 ? $t("newPage") : $t("updatePage") }}</strong
              >
            </div>
            <b-row>
              <b-col sm="12" class="text-center">
                <b-img
                  :src="getFeaturedImageSrc()"
                  class="img-radius"
                  alt="Featured"
                  thumbnail
                  rounded
                  @click="editFeaturedImage()"
                />
                <b-progress
                  :max="100"
                  height="1rem"
                  animated
                  style="margin: 2px"
                  v-if="form.featuredImage.uploadPercentage > 0"
                >
                  <b-progress-bar
                    :value="form.featuredImage.uploadPercentage"
                    variant="primary"
                  >
                    <span
                      >{{ $t("loading") }}:
                      <strong
                        >{{
                          form.featuredImage.uploadPercentage.toFixed(0)
                        }}%</strong
                      ></span
                    >
                  </b-progress-bar>
                </b-progress>
              </b-col>
              <b-col sm="12" class="text-center mt-1 mb-1">
                <input
                  type="file"
                  accept="image/*"
                  class="d-none"
                  ref="uploadFile"
                  v-on:change="() => fileLoaded()"
                />
                <BrandButton
                  :text="$t('browse')"
                  buttonIcon="fa fa-folder-open"
                  buttonVariant="primary"
                  @click="browse()"
                ></BrandButton>
              </b-col>
            </b-row>
            <b-row>
              <div class="col-sm-12">
                <ValidationableTextBox
                  prename="page"
                  id="title"
                  icon="fa fa-font"
                  :isRequired="true"
                  v-model="$v.form.title.$model"
                  :form="$v.form.title"
                  :value="$v.form.title"
                  @onLostFocus="onTitleLostFocus()"
                />
              </div>
              <div class="col-sm-12">
                <label>{{ $t("htmlContent") }}</label>
                <TextEditor
                  :content="form.htmlContent"
                  @input="(val) => (form.htmlContent = val)"
                />
              </div>
              <div class="col-sm-12">
                <ValidationableTextBox
                  prename="page"
                  id="url"
                  icon="fa fa-link"
                  :isRequired="true"
                  v-model="$v.form.url.$model"
                  :form="$v.form.url"
                  :value="$v.form.url"
                  @keydown="(e) => onUrlKeyDown(e)"
                />
              </div>
              <div class="col-sm-12">
                <ValidationableTextBox
                  prename="page"
                  id="seoTitle"
                  icon="fa fa-link"
                  :isRequired="true"
                  v-model="$v.form.seoTitle.$model"
                  :form="$v.form.seoTitle"
                  :value="$v.form.seoTitle"
                />
              </div>
              <div class="col-sm-12">
                <label for="seoKeywords">{{ $t("seoKeywords") }}</label>
                <CustomTagInputValidation
                  v-model="keyWords"
                  :keyWords="keyWords"
                  :form="$v.form.seoKeywords"
                  id="seoKeywords"
                  icon="fa fa-tags"
                  prename="page"
                  @changed="(val) => seoKeywordsChanged(val)"
                />
              </div>
              <b-col sm="12">
                <ValidationableTextArea
                  prename="page"
                  id="seoDescription"
                  :isRequired="true"
                  v-model="$v.form.seoDescription.$model"
                  :form="$v.form.seoDescription"
                  :value="$v.form.seoDescription"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="text-right">
                <div class="form-actions">
                  <BrandButton
                    buttonVariant="success"
                    buttonIcon="fa fa-save"
                    @click="save"
                    :text="$t('save')"
                  />

                  <BrandButton
                    buttonVariant="danger"
                    buttonIcon="fa fa-undo"
                    @click="back"
                    :text="$t('back')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { http } from "../../shared/http-base";
import httpGeneral from "../../shared/http";
import pageValidator from "../../validations/page-validator";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import ValidationableTextArea from "../../widgets/form/ValidationableTextArea";
import CustomTagInputValidation from "../../widgets/form/CustomTagInputValidation";
import CustomDropDown from "../../widgets/form/CustomDropDown";
import BrandButton from "../../widgets/form/CustomBrandButton";
import PhotoEditorModal from "../../widgets/news/PhotoEditorModal";
import stringHelper from "../../shared/string-helper";
import generalHelper from "../../shared/utils/general-helper";
import generatorsHelper from "../../shared/utils/generators-helper";
import toastHelper from "../../shared/toast-helper";
import { validationMixin } from "vuelidate";

export default {
  name: "PageAdd",
  components: {
    ValidationableTextBox,
    ValidationableTextArea,
    CustomTagInputValidation,
    BrandButton,
    PhotoEditorModal,
    CustomDropDown,
  },
  mixins: [validationMixin],
  validations: pageValidator.getValidations(),
  methods: {
    getFeaturedImageSrc() {
      if (
        this.form.featuredImage.localFile == null &&
        this.form.featuredImage.onlineFile == null
      ) {
        return "/img/noPic.png";
      } else if (this.form.featuredImage.localFile != null) {
        return this.form.featuredImage.localFile;
      } else {
        return this.form.featuredImage.onlineFile.fileName;
      }
    },
    editFeaturedImage() {
      if (this.form.featuredImage.onlineFile != null) {
        this.editModal = true;
      } else {
        toastHelper.error(this.$t("notUploadedYet"));
      }
    },
    fileLoaded() {
      let file = this.$refs.uploadFile.files[0];
      if (generalHelper.isImageFile(file)) {
        this.form.featuredImage.uploadPercentage = 1;
        this.form.featuredImage.localFile = URL.createObjectURL(file);
        this.calculateImageSize();
        this.startUpload(file);
      } else {
        toast.error(this.$t("fileIsNotValid"));
      }
    },
    saveAndUpload(base64) {
      this.editModal = false;
      this.remove(false);
      var block = base64.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = generatorsHelper.b64toBlob(realData, contentType);
      this.form.featuredImage.localFile = base64;
      this.calculateImageSize();
      this.startUpload(null, blob);
    },
    startUpload(file, blob = null) {
      let formData = new FormData();
      if (blob != null) {
        formData.append("file", blob, "image.jpg");
      } else {
        formData.append("file", file);
      }
      let self = this;
      http
        .post("/Files/upload", formData, {
          onUploadProgress: function (progressEvent) {
            this.form.featuredImage.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          this.form.featuredImage.onlineFile = response.data.data;
          this.form.featuredImage.uploadPercentage = 0;
        })
        .catch((e) => {
          if (blob == null) {
            self.remove();
          }
          if (!e.response.data.success) {
            toast.error(e.response.data.message);
          }
        });
    },
    browse() {
      this.$refs.uploadFile.click();
    },
    remove(withMessage = true) {
      if (this.form.featuredImage.onlineFile != null) {
        let model = {
          fileId: this.form.featuredImage.onlineFile.id,
        };
        http
          .post(
            `Files/deletefilebyid?fileId=${this.form.featuredImage.onlineFile.id}`
          )
          .then((response) => {
            if (withMessage) toast.success(response.message);
          });
      }
      this.form.featuredImage.localFile = null;
      this.form.featuredImage.onlineFile = null;
      this.form.featuredImage.uploadPercentage = 0;
      this.$refs.uploadFile.value = null;
    },
    calculateImageSize() {
      if (this.form === undefined) return;
      let img = new Image();
      img.onload = () => {
        this.editHeight = img.height;
        this.editWidth = img.width;
      };
      img.src =
        this.form.featuredImage.localFile == null
          ? this.form.featuredImage.onlineFile.fileName
          : this.form.featuredImage.localFile;
      //Load image size
    },
    onTitleLostFocus() {
      if (this.form.url == "") {
        this.form.url = stringHelper.standardUrl(this.form.title);
      }
    },
    onUrlKeyDown(e) {
      if (e.shiftKey || e.altKey || e.ctrlKey) e.returnValue = false;
      else e.returnValue = stringHelper.isValidNumChar(e.key);
    },
    seoKeywordsChanged(val) {
      this.keyWords = val;
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      let model = { ...this.page, ...this.form };

      if (this.form.featuredImage.onlineFile != null) {
        model.featuredImageFileId = this.form.featuredImage.onlineFile.id;
      }
      let path = model.id > 0 ? "pages/update" : "pages/add";
      httpGeneral.post(path, model).then((result) => {
        if (result && result.success) {
          toastHelper.success(result.message);
          this.$router.push({ path: "/page-list" });
        }
      });
    },
    back() {
      this.$router.back();
    },
    getUpdateForm(pageId) {
      let path = `pages/getbyid?pageId=${pageId}`;
      httpGeneral.get(path).then((page) => {
        if (page) {
          this.page = page;
          this.form.title = page.title;
          this.form.htmlContent = page.htmlContent;
          this.form.url = page.url;
          this.form.seoTitle = page.seoTitle;
          this.form.seoDescription = page.seoDescription;
          this.keyWords = page.seoKeywords ? page.seoKeywords.split(",") : null;
          if (page.featuredImageFile != null) {
            this.form.featuredImage = {
              localFile: null,
              onlineFile: page.featuredImageFile,
              uploadPercentage: 0,
            };
          }
          this.calculateImageSize();
        }
      });
    },
  },
  watch: {
    form: {
      handler(obj) {
        let standardUrl = stringHelper.standardUrl(obj.url);
        obj.url = standardUrl;
      },
      deep: true,
    },
    keyWords() {
      let keys = "";
      if (this.keyWords != null) {
        this.keyWords.forEach((element) => {
          keys = keys + "," + element;
        });
        this.page.seoKeywords = keys.substr(1);
      }
    },
  },
  data() {
    return {
      keyWords: [],
      editModal: false,
      editHeight: 0,
      editWidth: 0,
      form: {
        title: "",
        htmlContent: "",
        url: "",
        seoTitle: "",
        seoDescription: "",
        featuredImage: {
          localFile: null,
          onlineFile: null,
          uploadPercentage: 0,
        },
      },
      page: {
        id: 0,
        title: "",
        htmlContent: "",
        url: "",
        featuredImageFileId: null,
        seoTitle: "",
        seoKeywords: "",
        seoDescription: "",
      },
    };
  },
  created() {
    let pageId = this.$route.params.id ? this.$route.params.id : 0;
    if (pageId > 0) this.getUpdateForm(pageId);
  },
};
</script>
<style scoped>
.padding {
  padding: 3rem !important;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}
.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  ) !important;
  background: linear-gradient(to right, #ee5a6f, #f29263) !important;
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
  height: 120px;
  width: 120px;
  object-fit: contain;
  background-color: white !important;
  cursor: pointer;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}
</style>